.hiw-carousel-container {
  width: 100vw;
  max-width: none;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.hiw-carousel-indicators {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
  gap: 20px;
}

.hiw-carousel-indicator {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0.25rem;
  background-color: #d9d2c8;
  color: #333;
  z-index: 1;
  font-family: "Montserrat";
  font-size: 16px;
  font-weight: 800;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.hiw-carousel-indicator-active {
  background-color: #000;
  color: #fff;
}

.hiw-carousel-scroll-container::-webkit-scrollbar {
  display: none;
}

.hiw-carousel-content {
  display: flex;
  flex-direction: column;
  padding: 15px;
  transition: transform 0.3s ease;
  gap: 30px;
  padding-top: 5px;
}

.hiw-carousel-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
}

.hiw-carousel-image-container {
  border-radius: 20px;
  overflow: visible;
  min-height: 290px;
}

.hiw-carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
  border-radius: 20px;
}
