.notificationtext {
  position: relative;
  letter-spacing: -0.02em;
  font-weight: 600;
}
.notificationheader {
  color: white;
  align-self: stretch;
  background: linear-gradient(88.55deg, #36b48f, #36b48f);
  height: 57px;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.progress {
  align-self: flex-start;
  height: 4px;
  background-color: #75d8a3;
  transition: width 0.3s ease-in-out;
}
.logo-icon {
  width: auto;
  height: 30px;
}

.tech-icon {
  width: auto;
  height: 42px;
}

.title {
  width: 316px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 53px;
  font-size: 55px;
  font-weight: 600;
  display: inline-block;
}

.title-smaller {
  width: 316px;
  position: relative;
  letter-spacing: -0.02em;
  line-height: 45px;
  font-size: 45px;
  font-weight: 600;
  display: inline-block;
}

mux-player::part(media) {
  background-color: white !important;
}

mux-player video {
  background-color: white !important;
}

mux-player {
  width: 240px !important;
  position: relative !important;
  border-radius: 20px !important;
  height: 380px !important;
  overflow: hidden !important;
  flex-shrink: 0 !important;
  object-fit: cover !important;
  background-color: #eee !important;
  border: none !important;

  --loading-indicator: block;
  --rendition-selectmenu: none;
  --audio-track-selectmenu: none;
  --media-object-fit: cover;
  --seek-backward-button: none;
  --seek-forward-button: none;
  --center-controls: none;
  --top-captions-button: none;
  --play-button: none;
  --dialog: none;
  --play-button: none;
  --live-button: none;
  --seek-backward-button: none;
  --seek-forward-button: none;
  --mute-button: none;
  --captions-button: none;
  --airplay-button: none;
  --pip-button: none;
  --fullscreen-button: none;
  --cast-button: none;
  --playback-rate-button: none;
  --volume-range: none;
  --time-range: none;
  --time-display: none;
  --duration-display: none;
  --rendition-selectmenu: none;
  --controls-backdrop-color: rgb(0 0 0 / 0%);
  --controls: none;
}

video[slot="media"] {
  background-color: white !important;
}

mux-player::part(player) {
  border: none !important;
}

.slider {
  align-self: stretch;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 30px 0px;
  gap: 20px;
}

.carousel {
  display: flex;
  gap: 20px;
  animation: slide 22s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.cta-text {
  position: relative;
  letter-spacing: -0.01em;
  font-weight: 500;
}

a {
  text-decoration: none !important;
}

.right-arrow {
  width: 20px;
  position: relative;
  height: 20px;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: contain;
}
.cta {
  border-radius: 30px;
  background-color: #48bc9c;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 15px 17px 15px 27px;
  gap: 10px;
  font-size: 20px;
  color: #fff;
  font-family: Livvic;
}
.content {
  align-self: stretch;
  background: linear-gradient(188.22deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 32px 0px 0px;
  gap: 10px;
  text-align: center;
  color: #262524;
  font-family: "Lisu Bosa";
}
.page {
  width: 100%;
  position: relative;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
  color: #fff;
  font-family: Livvic;
  padding-bottom: 20px;
}

/* Desktop styles */
@media (min-width: 1024px) {
  body {
    background-image: url("https://plantvideos.s3.eu-north-1.amazonaws.com/plantsbg.jpg"); /* Add the background image */
    background-size: cover; /* Ensure the image covers the entire body */
    background-position: center; /* Center the background image */
    background-repeat: no-repeat;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 140vh; /* Full height of the viewport */
  }

  .subtitle {
    max-width: 340px !important;
  }

  .highPage {
    height: 1024px !important;
  }

  .plant-selection-grid,
  .plant-selection-option,
  .plant-selection-option-selected,
  .shipping-form {
    max-width: 340px;
  }

  .hiw-carousel-image-container {
    max-width: 340px;
  }

  .content {
    overflow: scroll;
  }

  .page {
    width: 375px; /* Set a mobile-sized width for desktop */
    height: 900px; /* Set a mobile-sized height for desktop */
    margin: 23px auto;
    overflow-y: scroll;
    border-radius: 6px;
    box-shadow: 1 1 30px rgba(0, 0, 0, 0.087);
    background-color: rgba(
      255,
      255,
      255,
      1
    ); /* Optional: Add a translucent background if needed */
    box-shadow: #0000003d 1px 1px 70px;
    outline: 6px solid rgba(255, 255, 255, 0.2);
  }
}

.subtitle {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 24px;
  max-width: 90%;
  letter-spacing: -0.2px;
  margin: 10px auto;
  text-align: center;
  color: #555;
  font-weight: 500;
  padding: 2px 10px 4px 10px;
}

@keyframes zoomIn {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.7);
  }
}

.how-it-works-video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  overflow: hidden;
  border-radius: 10px;
  width: 90%;
  background-color: #fff;
  border: none !important;
}

.how-it-works-subtitle {
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  line-height: 24px;
  max-width: 90%;
  letter-spacing: -0.2px;
  margin: 10px auto;
  text-align: center;
  color: #555;
  padding: 2px 10px 4px 10px;
  font-weight: 500;
}

.how-it-works-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transform-origin: 50% 100%;
  animation: zoomIn 5s ease-in-out forwards;
}

.back-button {
  position: absolute;
  left: 15px;
  top: 31px;
  transform: translateY(-50%);
  border: none;
  cursor: pointer;
  padding: 0;
}

.media-outlets {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  padding-top: 30px;
}

.media-outlets-image {
  width: 90%;
  height: 100%;
  object-fit: cover;
}

.media-outlets-title {
  font-family: Livvic;
  font-size: 17px;
  font-weight: 400;
  color: #696969;
}

.question-with-image .title {
  font-size: 42px;
  line-height: 48px;
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.question-with-image .cta {
  width: 100%;
}

.question-with-image .tech-icon {
  margin-bottom: 20px;
}

.family-photo {
  align-self: center;
  padding: 5px;
  padding-bottom: 30px;
  min-height: 120px;
  width: 55%;
}

.hiw-carousel-item {
  min-width: 300px;
}

.image-loading {
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.image-loaded {
  opacity: 1 !important; /* Fade in the image once loaded */
}

.light-explainer {
  align-self: center;
  padding: 5px;
  padding-bottom: 30px;
  width: 90%;
  min-height: 180px;
}

.pets-photo {
  align-self: center;
  padding: 10px;
  padding-bottom: 40px;
  width: 55%;
  min-height: 140px;
}

.plant-selection-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0;
}

.plant-selection-option {
  position: relative;
  width: 90vw;
  margin: 0 auto;
  border-radius: 20px;
  background-color: #eee;
  overflow: hidden;
  border: 1px solid #00000021;
}

.plant-selection-option-selected {
  border: 4px solid #48bc9c;
  margin-bottom: -2px;
  margin-top: -4px;
}

.plant-selection-image-container {
  position: relative;
  width: 100%;
  padding-top: 100%; /* This creates a 1:1 aspect ratio */
  background-color: #f0f0f0; /* Light grey background */
}

.plant-selection-image-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
}

.plant-selection-image {
  position: absolute;
  bottom: 50px;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.plant-selection-colors {
  position: absolute;
  left: 0px;
  top: 4px;
}

.plant-selection-color-selected {
  border: 1.2px solid #0000005c !important;
  margin: 12px !important;
  box-shadow: 1px 1px 2px #00000024;
}

.plant-selection-color {
  width: 36px;
  height: 36px;
  border-radius: 100px;
  margin: 12px;
  border: 1px solid #0000002e;
  opacity: 0.8;
}

.plant-selection-image-loaded {
  opacity: 1;
}

.plant-selection-info-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    to top,
    rgb(255 255 255),
    rgb(255 255 255 / 80%),
    rgb(255 255 255 / 0%)
  );
  padding: 25px 20px 20px 20px;
  color: #213b33;
  text-align: left;
}

.plant-selection-name {
  font-size: 24px;
  font-weight: bold;
  margin: 0 0 4px 0;
  font-family: "Livvic";
}

.plant-selection-description {
  font-size: 17px;
  margin: 0 0 9px 0;
  font-weight: 500;
  color: #263e37;
  font-family: "Livvic";
}

.plant-selection-price-tag {
  display: flex;
  align-items: center;
  font-family: "Livvic";
  font-weight: 600;
  letter-spacing: -0.1px;
  gap: 7px;
}

.plant-selection-original-price {
  text-decoration: line-through;
  font-size: 14px;
  opacity: 0.7;
  display: none;
}

.plant-selection-discounted-price {
  font-size: 20px;
  font-weight: 800;
  color: #263e37;
}

.plant-selection-discount-percentage {
  font-size: 12px;
  background-color: #ef5536;
  color: white;
  padding: 5px 8px;
  border-radius: 4px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.plant-selection-check-mark {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #48bc9c;
  border-radius: 30px;
  height: 24px;
  padding: 10px;
  width: 24px;
}

.fixed-next-button-container {
  position: fixed;
  bottom: 0;
  padding: 20px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  border-top: solid 1px #0000001a;
  background-color: #fff;
  box-shadow: 2px 2px 15px #00000014;
  z-index: 10;
}

.plant-selection-cta {
  display: flex;
  align-items: center;
  background-color: #48bc9c;
  color: white;
  padding: 10px 20px;
  border-radius: 30px;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.plant-selection-cta-text {
  margin-right: 10px;
}

.plant-selection-right-arrow {
  width: 20px;
  height: 20px;
}

.plant-selection-add-button {
  text-align: center;
  color: #b4b4b4;
  position: absolute;
  font-size: 35px;
  line-height: 20px;
  right: 0px;
  top: 0px;
  z-index: 1;
  margin: 10px;
  padding: 10px;
  border: solid 1px #00000032;
  border-radius: 50px;
  font-family: "Montserrat";
  font-weight: 200;
  box-shadow: 1px 1px 6px #0000001a;
}

/* ------- Volume discount popup ------- */

/* Popup Overlay */
.plant-selection-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 1000;
  animation: plant-selection-fadeIn 0.3s ease-out;
}

/* Popup Content */
.plant-selection-popup-content {
  color: #48bc9c;
  background-color: white;
  padding: 24px 0px;
  border-radius: 20px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 90%;
  width: 450px;
  text-align: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  width: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  animation: plant-selection-slideUp 0.3s ease-out;
}

/* Popup Animations */
@keyframes plant-selection-slideUp {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

.plant-selection-popup-header p {
  color: #666;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
  padding: 0px 30px;
}

/* Volume Discount Styles */
.plant-selection-volume-discount {
  background-color: #f0f8ffc4;
  padding: 0px 20px 30px 20px;
  margin-bottom: 1.5rem;
  border-top: 1px solid #80808054;
  border-bottom: 1px solid #80808054;
}

.plant-selection-volume-discount > p {
  color: #666;
  font-size: 1rem;
  margin-bottom: 1rem;
}

/* Close Button */
.plant-selection-close-popup {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: none;
  border: none;
  color: #999;
  cursor: pointer;
  transition: color 0.2s ease;
}

.plant-selection-close-popup:hover {
  color: #333;
}

/* Continue Shopping Button */
.plant-selection-continue-shopping {
  background-color: #48bc9c;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 25px;
  font-size: 20px;
  font-weight: medium;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.plant-selection-continue-shopping:hover {
  background-color: #48bc9c;
  transform: translateY(-2px);
}

.plant-selection-continue-shopping:active {
  transform: translateY(0);
}

/* Confetti Container (unchanged) */
.plant-selection-confetti-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 1001;
}

.plant-selection-volume-discount-offer {
  width: 100%;
  margin-top: 26px;
  margin-bottom: 30px;
}

.plant-selection-content {
  padding-bottom: 160px;
}

.select-plants-next-fixed-bottom-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 0 -2px 23px rgba(0, 0, 0, 0.07);
  padding: 0px 0px;
  z-index: 10;
  border-top: 1px solid #0000002b;
  background: #f4fbfc;
}

.select-plants-next-total-price-label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-right: 10px;
}

.select-plants-next-discount-message {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  color: #d51b1b;
  margin-bottom: 11px;
  margin-top: 4px;
  font-family: "Livvic";
  padding-top: 10px;
}

.select-plants-next-bottom-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px 20px;
  background: white;
  gap: 7px;
}

.select-plants-next-discount-levels {
  display: flex;
  gap: 5px;
  justify-content: space-around;
  padding: 2px 18px 7px 18px;
  border-bottom: 1px solid #0000001f;
}

.select-plants-next-discount-square {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #666;
  font-weight: 600;
  font-size: 14px;
  font-family: "Montserrat";
  transition: all 0.3s ease;
  border-top: 5px solid #e1e1e1;
  color: #aaaaaa;
}

.select-plants-next-discount-square.active {
  border-top: 5px solid #ef5536;
  color: #ef5536;
  padding: 3px;
}

.select-plants-next-total-price {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.select-plants-next-total-price-strikeout {
  display: flex;
  color: grey;
  font-weight: 600;
  font-size: 16px;
  text-decoration: line-through;
  align-self: flex-end;
  margin-right: 7px;
}

.select-plants-next-total-price-numbers {
  display: flex;
  flex-direction: row;
}

.select-plants-next-cta {
  display: flex;
  align-items: center;
  background-color: #4caf50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.select-plants-next-cta:hover {
  background-color: #45a049;
}

.select-plants-next-cta-text {
  margin-right: 10px;
}

.select-plants-next-right-arrow {
  width: 20px;
  height: 20px;
}

.lc-button-container {
  width: 90%;
  max-width: 400px;
  margin-top: 10px;
}

.lc-option {
  display: flex;
  align-items: center;
  color: #000;
  padding: 15px;
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 60px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: "Livvic";
}

.lc-option:hover {
  background-color: #e0e0e0;
}

.lc-option-icon {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  margin-left: 3px;
}

.lc-option-text {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  gap: 2px;
  padding-right: 15px;
  text-align: left;
}

.lc-option-title {
  font-size: 18px;
  font-weight: bold;
}

.lc-option-subtitle {
  font-size: 16px;
  color: #000;
}

.lc-right-arrow {
  width: 20px;
  height: 20px;
}

.loading-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.show-plants-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.show-plants-button:hover {
  background-color: #45a049;
}

.searching-plants-container {
  font-family: "Livvic", sans-serif;
  color: #333;
  max-width: 400px;
  margin: 0 auto;
  text-align: center;
  padding: 40px 20px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.searching-plants-title {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 30px;
  color: #00796b;
}

.searching-plants-filters-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  background-color: #f9f9f9;
  padding: 20px;
  width: 80%;
  text-align: center;
  border-radius: 8px;
  font-size: 16px;
  font-family: "Livvic";
  font-weight: 500;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #0000002b;
}

.searching-plants-filter-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 16px;
}

.searching-plants-check-icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  fill: #00796b;
}

.searching-plants-progress-container {
  position: relative;
  width: 140px;
  height: 140px;
}

.searching-plants-progress-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transition: all 0.3s linear;
}

.searching-plants-progress-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  border-radius: 50%;
  background-color: white;
}

.searching-plants-icon-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.searching-plants-icon {
  width: 80px;
  height: 80px;
  fill: #00796b;
  transition: opacity 0.3s ease;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
}

.searching-plants-icon.visible {
  opacity: 1;
}

.searching-plants-count {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 600;
  font-family: "Livvic";
}

.searching-plants-button {
  background-color: #00796b;
  color: white;
  border: none;
  padding: 14px 28px;
  font-size: 18px;
  font-weight: 700;
  border-radius: 30px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
  transform: translateY(20px);
}

.cta.visible {
  opacity: 1;
  animation: fadeInPlain 0.5s ease-out forwards;
}

.visibleAnimation {
  opacity: 1;
  animation: fadeInPlain 1.2s ease-out forwards;
}

.hiddenAnimation {
  opacity: 0;
  animation: fadeOutPlain 0.5s ease-out forwards;
}

.visible {
  opacity: 1;
}

.hidden {
  opacity: 0;
}

.cta.hidden,
.content.hidden {
  opacity: 0;
}

.subtitle.loading-subtitle {
  font-weight: 600;
  opacity: 0;
}

.title.loading-title {
  font-family: "Livvic";
  font-size: 36px;
}

@keyframes fadeInPlain {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutPlain {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease-out forwards;
}

.searching-plants-filters-list,
.searching-plants-progress-container,
.searching-plants-count {
  opacity: 0;
}

.searching-plants-filters-list.fade-in {
  animation-delay: 0.2s;
}
.searching-plants-progress-container.fade-in,
.searching-plants-count.fade-in {
  animation-delay: 0.8s;
}

.plant-selection-searching-plants-filters-list {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  align-items: stretch;
  padding: 10px;
  width: 80%;
  text-align: center;
  border-radius: 8px;
  font-size: 14px;
  font-family: "Livvic";
  font-weight: 500;
  gap: 10px;
}

.plant-selection-searching-plants-filter-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-size: 14px;
  flex: 1 1;
  flex-direction: column;
  justify-content: flex-start;
  padding: 5px;
  gap: 5px;
  background-color: #f4fbfc;
  padding-top: 12px;
  padding-bottom: 13px;
  border-radius: 4px;
}

.plant-selection-searching-plants-filter-item .searching-plants-check-icon {
  margin-right: 0px !important;
}

.checkout-summary {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.checkout-plant-item {
  display: flex;
  align-items: left;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 10px;
  font-family: "Montserrat";
  letter-spacing: -0.3px;
}

.content.checkout-content {
  padding: 20px;
  padding-top: 32px;
  padding-bottom: 80px;
}

.checkout-plant-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 15px;
  border: 1px solid #0000001c;
}

.checkout-plant-details {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}

.checkout-plant-details h3 {
  font-size: 18px;
}

.checkout-plant-details h3 {
  margin: 0 0 5px 0;
}

.checkout-plant-details p {
  margin: 0;
  font-size: 14px;
  color: #666;
}

.checkout-plant-price {
  font-weight: bold;
  color: #000;
}

.checkout-total {
  padding: 20px;
  border-radius: 10px;
  font-family: "Montserrat";
  letter-spacing: -0.3px;
  font-weight: 400;
}

.checkout-discount,
.checkout-subtotal {
  font-size: 15px;
}

.checkout-label {
  flex: auto;
  text-align: start;
}

.checkout-value {
  padding-left: 6px;
  font-weight: 500;
}

.checkout-subtotal,
.checkout-discount,
.checkout-final-total {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  align-items: center;
}

.checkout-final-total {
  font-weight: bold;
  font-size: 18px;
  border-top: 1px solid #ccc;
  padding-top: 10px;
}

.shipping-form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 85vw;
}

.shipping-form input,
.shipping-form select {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.shipping-form select {
  max-width: 140px;
  color: #666;
}

.shipping-time {
  font-size: 14px;
  margin-top: 12px;
}

.state-zip-container {
  display: flex;
  gap: 16px;
}

.state-zip-container select,
.state-zip-container input {
  flex: 1;
  width: 100%;
}

.shipping-form input:focus,
.shipping-form select:focus {
  outline: none;
  border-color: #4caf50;
}

.oos-loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  opacity: 1;
}

.oos-message {
  text-align: center;
  opacity: 1;
}

.oos-title {
  color: #000000;
  margin-bottom: 20px;
}

.oos-text {
  margin-bottom: 15px;
}

.oos-button-container {
  margin-top: 30px;
}

.oos-return-home-button,
.oos-email-us-button {
  color: #444;
  background-color: #ddd;
}

.oos-button {
  width: 70%;
}

.oos-return-home-button {
  background-color: #4caf50;
  color: white;
}

.oos-email-us-button {
  background-color: #f0f0f0;
  color: #333;
}

.oos-return-home-button:hover,
.oos-email-us-button:hover {
  opacity: 0.9;
}

.shipping-form input,
.shipping-form select {
  border: 1px solid #ccc;
  transition: border-color 0.3s ease;
}

.shipping-form .invalid-field {
  border-color: #ff0000;
  color: #ff0000;
}

.shipping-form .invalid-field::placeholder {
  color: #ff9999;
}

/* Define the animation for fading the background color */
@keyframes fadeBackground {
  0% {
    background-color: rgba(0, 0, 0, 0.05); /* Initial color */
  }
  50% {
    background-color: #fff;
  }
  100% {
    background-color: rgba(0, 0, 0, 0.05); /* Back to initial */
  }
}

/* Class to trigger the animation */
.lc-option.animate {
  animation: fadeBackground 1s ease-in-out; /* Animation duration and easing */
  animation-iteration-count: 1; /* Ensures the animation runs only once */
}

.extra-pad {
  padding-bottom: 60px;
}

.cta-checkout {
  margin-left: auto;
}
